<template>
  <div id="page">
    <PageHeader
      :pageTitle="title + ' - ' + pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="8" v-if="dataFetched">
        <v-card class="my-2">
          <v-card-title>Farben</v-card-title>
          <v-card-text>
            <Patcher
              :itemValue="data.primaryColor"
              itemName="primaryColor"
              itemLabel="Primärfarbe"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="input"
              inputType="color"
              @emit="data.primaryColor = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.secondaryColor"
              itemName="secondaryColor"
              itemLabel="Sekundärfarbe"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="input"
              inputType="color"
              @emit="data.secondaryColor = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.red"
              itemName="red"
              itemLabel="Rot"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="input"
              inputType="color"
              @emit="data.red = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.yellow"
              itemName="yellow"
              itemLabel="Gelb"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="input"
              inputType="color"
              @emit="data.yellow = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.green"
              itemName="green"
              itemLabel="Grün"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="input"
              inputType="color"
              @emit="data.green = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.blue"
              itemName="blue"
              itemLabel="Blau"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="input"
              inputType="color"
              @emit="data.blue = $event.value"
            ></Patcher>

            <Patcher
              :itemValue="data.alarm"
              itemName="alarm"
              itemLabel="Alarm"
              :hideLabel="false"
              :route="patchRoute"
              inputElement="input"
              inputType="color"
              @emit="data.alarm = $event.value"
            ></Patcher>
          </v-card-text>
        </v-card>

        <v-card class="my-2">
          <v-card-title>Logo</v-card-title>
          <v-card-text>
            <Patcher
              :itemValue="data.mediaUuid"
              itemName="mediaUuid"
              itemLabel="Logo"
              :hideLabel="false"
              :route="patchRoute"
              inputElement=""
              inputType="media"
              @emit="data.mediaUuid = $event.value"
            ></Patcher>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="4">
        <Subnavigation
          root="/csm/company-manager/:id"
          :pageTitle="pageTitle"
        ></Subnavigation>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import Subnavigation from "@/components/ui/Subnavigation.vue";
import Patcher from "@/components/ui/Patcher.vue";
export default {
  name: "Design",

  components: {
    PageHeader,
    Subnavigation,
    Patcher,
  },

  data() {
    return {
      pageTitle: "Design",
      pageDescription:
        "Corporate Design-Einstellungen für die App und die Landingpages",
      title: "",
      data: [],
      preloader: false,
      editName: false,
      dataFetched: false,
      patchRoute: "companies/" + this.$route.params.id + "/designs",
    };
  },

  created: function() {
    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      /* USEING REUSEABLE MIXIN METHOD */
      let response = await this.getRequest(
        "companies/" + this.$route.params.id + "/designs"
      ); // await data from mixin
      this.preloader = false;
      this.dataFetched = true;
      this.data = response.data.data;
      this.title = response.data.data.name;

      /* Fetching Companyname for Headline */
      /* USEING REUSEABLE MIXIN METHOD */
      response = await this.getRequest("companies/" + this.$route.params.id); // await data from mixin
      this.title = response.data.data[0].name;
    },
  },

  mixins: [apiRequest],
};
</script>
